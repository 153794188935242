<template>
    <div class="text-center pt-10">
        <h1>{{ $t("no_company.title") }}</h1>
        <p v-html="$t('no_company.text')"></p>
        <img :alt="$t('no_company.title')" class="mt-3" src="../assets/images/undraw_Lost_re_xqjt.svg"
             style="max-width: 100%;">
    </div>
</template>

<script>

export default {
    name: "NoCompany",
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>